const dateTempusObjects = {};

$('.datepicker').each(function () {
    let dateFormat = $('#date-format-div').data('date-format');
    dateTempusObjects[$(this).prop("id")] = new tempusDominus.TempusDominus($(this)[0], {
        display: {
            components: {
                calendar: true,
                date: true,
                month: true,
                year: true,
                decades: true,
                clock: false,
                hours: false,
                minutes: false,
                seconds: false,
            },
            buttons: {
                today: true,
                clear: true,
                close: true
            },
        },
        localization: {
            locale: langCode,
            format: dateFormat,
        }
    });
});

$('.datetimepicker').each(function () {
    let dateFormat = $('#date-format-div').data('date-format');
    let enableSeconds = $(this).data("seconds") !== 'undefined';
    let fullFormat= `${dateFormat} ${enableSeconds ? "HH:mm:ss" : "HH:mm"}`;
    dateTempusObjects[$(this).prop("id")] = new tempusDominus.TempusDominus($(this)[0], {
        display: {
            components:{
                seconds: enableSeconds
            },
            buttons: {
                today: true,
                clear: true,
                close: true
            },
        },
        localization: {
            locale: langCode,
            format: fullFormat,
            hourCycle: "h23"
        }
    });
});

$('.timepicker').each(function () {
    let enableSeconds = $(this).data("seconds") !== 'undefined';
    dateTempusObjects[$(this).prop("id")] = new tempusDominus.TempusDominus($(this)[0], {
        display: {
            viewMode: "clock",
            components: {
                decades: false,
                year: false,
                month: false,
                date: false,
                hours: true,
                minutes: true,
                seconds: enableSeconds
            }
        },
        localization: {
            format: enableSeconds ? "HH:mm:ss" : "HH:mm",
            hourCycle: "h23"
        }
    });
});


let blockUI; // Declare as global to allow release it if necessary
let blockUIMessage; // Declare as global to allow custom message for blockUi

$('.form-blockUI-button').on('click', function (event) {
    let target = $(".blockUI-target")[0];
    let form = $(".blockUI-target").find("form:first")[0];
    if (form.checkValidity()) {
        blockUI = new KTBlockUI(target, {
            message: blockUIMessage || `<h2 class="fw-bold"><span class="spinner-border text-primary me-1"></span> ${gettext("Procesando ...")}</h2>`,
        });
        blockUI.block();
    }
});

$('.form-blockUI-hide-modal-button').on('click', function (event) {
    let form = $(".blockUI-target").find("form:first")[0];
    let formModal = $(this).parents(".modal:first")[0];
    let modal = bootstrap.Modal.getInstance(formModal);

    if (form.checkValidity()) {
        modal.hide();
    }

});

$('.blockUI-button').on('click', function (event) {
    let target = $(".blockUI-target")[0];
    blockUI = new KTBlockUI(target, {
        message: blockUIMessage || `<h2 class="fw-bold"><span class="spinner-border text-primary me-1"></span> ${gettext("Procesando ...")}</h2>`,
    });
    blockUI.block();
});

$(".language").click(function (e) {
    let language = ($(this).find(".lang-id").text());
    let form = $("#language-form");
    $("#language-form input[name='language']").val(language);
    form.submit();
});


class Utils {
    constructor() {
    }

    static getWebSocketSchema() {
        return window.location.protocol == "https:" ? "wss" : "ws";
    }

    static getHost() {
        return window.location.host;
    }

    static getOrigin() {
        return window.location.origin;
    }

    static isTopVisible(element) {
        var elementRect = element.getBoundingClientRect();
        return elementRect.top >= 0 && elementRect.top <= window.innerHeight;
    }

    static isBottomVisible(element) {
        var elementRect = element.getBoundingClientRect();
        return elementRect.bottom >= 0 && elementRect.bottom <= window.innerHeight;
    }
}
